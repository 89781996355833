<template>
  <div class="grid grid-cols-2 gap-x-6 gap-y-4 transition-all md:gap-y-12">
    <EventSeancesCard
      v-for="event in items"
      :key="event.id"
      :value="event"
      :class="event.type === 'big' ? 'lg:max-w-4/5 lg:col-span-2' : 'lg:col-span-1'"
      class="col-span-2 rounded-lg border border-secondary bg-primary p-3 md:border-0 md:bg-transparent md:p-0"
      @show-more="emit('showTimetable', event.id)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { type Event, EventSeancesCard } from '@/5_entities/Event'
import { type Venue } from '@/5_entities/Venue'

type PropType = {
  value: Venue['repertoire']
}
type EmitType = {
  (e: 'showTimetable', id: Event['id']): void
}
interface RepertoireItem extends Event {
  type: 'big' | 'small'
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()

const MAX_SEANCES = 3
const items = computed((): RepertoireItem[] => {
  const itemsWithMoreSeances: RepertoireItem[] = []
  const itemsWithLessSeances: RepertoireItem[] = []
  const result: RepertoireItem[] = []

  props.value.forEach((item) => {
    item.seances.length >= MAX_SEANCES
      ? itemsWithMoreSeances.push({ ...item, type: 'big' })
      : itemsWithLessSeances.push({ ...item, type: 'small' })
  })

  while (itemsWithMoreSeances.length || itemsWithLessSeances.length) {
    const bigItem = itemsWithMoreSeances.slice(0, 1)
    const smallItems = itemsWithLessSeances.slice(0, 2)

    result.push(...bigItem, ...smallItems)

    itemsWithMoreSeances.splice(0, 1)
    itemsWithLessSeances.splice(0, 2)
  }

  return result
})
</script>
